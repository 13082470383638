import React from "react"
const Code = ({ children }) => {
  return (
    <code
    >
      {children}
    </code>
  )
}

export default Code
